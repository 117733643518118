const { start } = require("@popperjs/core");


//API url
const uploadUrl = "/json-xml";
const language = document.querySelector('#languageSwitch').textContent.trim();

const translate = {
    "Upload file": "Загрузить файл",
    "Conversion from text": "Конвертация из текста",
    "Conversion from file": "Конвертация из файла",
    "Download file": "Скачать файл",
    "Conversion in progress, do not close the tab": "Идет процесс конвертации, не закрывайте вкладку",
    "Download...": "Загрузка...",
    "Conversion error, please try again": "Ошибка конвертации, попробуйте снова",
    "Cancel": "Отмена",
    "Drag and drop your file into this field or upload it by clicking the button below": "Перетащите файл в это поле или загрузите его нажав на кнопку ниже",
    "The file has been successfully uploaded, now you can proceed to conversion": "Файл успешно загружен, теперь вы можете перейти к конвертации",
    "The file conversion was successful, now you can download the file": "Конвертация файла прошла успешно, теперь вы можете скачать файл",
    "File conversion error, try again": "Ошибка конвертации файла, попробуйте снова",
    "The file is being converted, do not close the tab.": "Идет конвертация файла, не закрывайте вкладку.",
    "Convert": "Конвертировать",
    "Convert again": "Конвертировать снова",
    "File:": "Файл:",
    "Paste your JSON text": "Вставьте ваш JSON текст",
    "Paste your XML text": "Вставьте ваш XML текст",
    "Result JSON text": "Результат JSON текст",
    "Result XML text": "Результат XML текст",
    "File upload error": "Ошибка загрузки файла",
    "No file selected or invalid format": "Не выбран файл или неверный формат",
    "You can only upload json or xml": "Можно загружать только json или xml",
    "Loading error": "Ошибка загрузки",
    "sec.": "сек.",
    "The waiting time for a response from the server has expired": "Превышено время ожидания ответа от сервера",
    "The file size is too large": "Размер файла слишком большой"
};


function lang(text){
    if(language != 'English')
        return translate[text];
    else return text
}
// Статус
const statusText = document.querySelector('#uploadFormStatusMessage');

let setStatus = (text) => {
    statusText.textContent = text;
}


// Файловая форма и drag&Drop для файла
const uploadForm = document.querySelector('#uploadForm .form-upload__grag');
const inputFile = document.querySelector("#uploadFormFile");
const dropFileZone = document.querySelector(".form-upload__grag");
const uploadInput = document.querySelector(".form-upload__input");
const handFileUploadBlock = document.querySelector(".form-upload__action-choice");
const convertFileButton = document.querySelector("#formUploadConvertBtn");
const downloadFileButton = document.querySelector("#formUploadDownloadBtn");
const convertAgain = document.querySelector("#convertAgainBtn");
const initSvg = document.querySelector('#initFileSvg');
const uploadSvg = document.querySelector('#uploadFileSvg');
const sucessSvg = document.querySelector('#sucessFileSvg');
const errorSvg = document.querySelector('#errorFileSvg');
const svgText = document.querySelector('#actionChoice');
const labelInputFile = document.querySelector('#actionChoice .form-upload__btn label');
const convertingProcess = document.querySelector('#convertingProcess');
const convertFileTimer = document.querySelector('#convertFileTimer');
const formUploadFileInfo = document.querySelector('#formUploadFileInfo');
const formUploadFileInfoText = document.querySelector('#formUploadFileInfoText');
const fileNameLabel = document.querySelector('.form-upload__btn label.custom-file-upload');
const formDownloadOpts = document.querySelector('#formDownload');
const resetFileBtn = document.querySelector('#resetFile');
const cancelBtnWrapper = document.querySelector('#fileConvertCancel');
const cancelBtn = document.querySelector('#fileConvertCancel button');

["dragover", "drop"].forEach(function(event) {
    document.addEventListener(event, function(evt) {
      evt.preventDefault()
      return false
    })
  })

inputFile.addEventListener('change', function() {
    const fileName = inputFile.value.split('\\').pop();
    document.querySelector('.custom-file-upload').textContent = fileName || lang('Download file');
});

resetFileBtn.addEventListener("click", (e) => {
    e.preventDefault();
    initFileForm();
})

convertFileButton.addEventListener("click", (e) => {
    e.preventDefault();
    convertFile(convertFileButton);
})

downloadFileButton.addEventListener("click", (e) => {
    e.preventDefault();
    downloadFile(downloadFileButton);
})

convertAgain.addEventListener("click", (e) => {
    e.preventDefault();
    initFileForm();
})

cancelBtn.addEventListener("click", (e) => {
    fetchData('cancel');
    initFileForm();
    setTimeout(() => {
        setStatus('');
    }, 10);
})


uploadInput.addEventListener("change", (event) => {
    event.preventDefault();
    const file = uploadInput.files?.[0];
    if (file && (file.type.includes("json") || file.type.includes("xml")) ) {
        clearFileForm();
        convertingProcess.removeAttribute('hidden');
        
        prepareData(file, 'file');
    } else {
        uploadForm.style.cssText = 'background: rgba(225, 77, 77, 0.05);'
        svgText.querySelector('[data-init]').setAttribute('hidden', true);
        initSvg.setAttribute('hidden', true);
        labelInputFile.setAttribute('hidden', true);
        errorSvg.removeAttribute('hidden');
        svgText.querySelector('[data-error-format]').removeAttribute('hidden');
        svgText.removeAttribute('hidden');
        setTimeout(() => {
            initFileForm();
        }, 3000);
        return false;
    }
})


dropFileZone.addEventListener("dragenter", function(event) {
    if (!dropFileZone.contains(event.relatedTarget)) {
        dropFileZone.classList.add("active");
        handFileUploadBlock.classList.add('disabled');
    }
})

dropFileZone.addEventListener("dragleave", function(event) {
    if (!dropFileZone.contains(event.relatedTarget)) {
        dropFileZone.classList.remove("active");
        handFileUploadBlock.classList.remove('disabled');
    }
})

dropFileZone.addEventListener("drop", function(event) {
    if (!dropFileZone.contains(event.relatedTarget)) {
        dropFileZone.classList.remove("active");
        handFileUploadBlock.classList.remove('disabled');
        const file = event.dataTransfer?.files[0];

        if (!file) {
            return false;
        } else{
            console.log(file.type.includes("json") || file.type.includes("xml"));
        }
        fileNameLabel.textContent = file.name;

        if (file.type.includes("json") || file.type.includes("xml") ) {
            clearFileForm();
            convertingProcess.removeAttribute('hidden');

            uploadInput.files = event.dataTransfer.files;
            prepareData(file, 'file');
        } else {
            uploadForm.style.cssText = 'background: rgba(225, 77, 77, 0.05);'
            svgText.querySelector('[data-init]').setAttribute('hidden', true);
            initSvg.setAttribute('hidden', true);
            labelInputFile.setAttribute('hidden', true);
            errorSvg.removeAttribute('hidden');
            svgText.querySelector('[data-error-format]').removeAttribute('hidden');
            svgText.removeAttribute('hidden');
            setTimeout(() => {
                initFileForm();
            }, 3000);
            return false;
        }
    }
});

// Строковая форма
const formSend = document.querySelector('textarea#formToSend');
const formResponse = document.querySelector('textarea#form-to-response');
const convertProcess = document.querySelector('#converting-process');
const convertError = document.querySelector('#converting-process-error');
const errorBntWrapper = document.querySelector('#converterTextCancel');
const errorButton = document.querySelector('#converterTextCancel button');
const formSendBtns = document.querySelector('#formSendBtns');
const formResponseBtns = document.querySelector('#formResponseBtns');
const convertDataBtn = document.querySelector("#convertDataBtn");
const sendFormDownloadBtn = document.querySelector("#downloadSendForm");
const sendFormCopyBtn = document.querySelector("#copySendForm");
const responseFormDownloadBtn = document.querySelector("#downloadResponseForm");
const responseFormCopyBtn = document.querySelector("#copyResponseForm");
const changeFormatBtn = document.querySelector('#changeFormat');
const copyBtns = document.querySelectorAll('#copySendForm, #copyResponseForm');
const downloadBtns = document.querySelectorAll('#downloadSendForm, #downloadResponseForm');


formSend.addEventListener("input", (event) => {
    formSendBtns.setAttribute('hidden', true);

    if(event.target.value){
        if(!convertError.hasAttribute('hidden')){
            convertError.setAttribute('hidden', true);
            formResponse.value = '';
            formResponse.removeAttribute('hidden');
        }
        convertDataBtn.removeAttribute('hidden');
        errorBntWrapper.setAttribute('hidden', true);
        
    }else{
        convertDataBtn.setAttribute('hidden', true);
    }
})

convertDataBtn.addEventListener("click", (e) => {
    e.preventDefault();
    formResponse.setAttribute('hidden', true);
    formResponseBtns.setAttribute('hidden', true);
    formSendBtns.setAttribute('hidden', true);
    convertDataBtn.setAttribute('hidden', true);
    convertProcess.removeAttribute('hidden');
    errorBntWrapper.removeAttribute('hidden');
    prepareData();
})
changeFormatBtn.addEventListener("click", (e) => {
    e.preventDefault();
    clearForm();
    changeTextArea();
    convertDataBtn.setAttribute('hidden', true);
})

errorButton.addEventListener("click", (e) => {
    e.preventDefault();
    clearForm();
})

copyBtns.forEach(button => {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        copyFromForm(button.id);
    });
  }); 

downloadBtns.forEach(button => {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        prepareForDownload(button.id);
    });
  }); 

let currentController = null;
async function fetchData(body, headers) {
    if (body === 'cancel') {
        if (currentController) {
          currentController.abort();
        }
        return;
    }
    const timeout = 120000; 
 
    const timer = setTimeout(() => {
        currentController.abort();
    }, timeout);

    convertFileTimer.textContent = 120 + ' ' + lang('sec.');
    convertingProcess.removeAttribute('hidden');

    let i = 119;
    const timerInterval = setInterval(async () => {
        convertFileTimer.textContent = i + ' ' + lang('sec.');
      i--;
     
    }, 1000);

  
    let controller = new AbortController();
    currentController = controller;
    const signal = controller.signal;
    
    try{
        const response = await fetch(uploadUrl, {
            method: 'POST',
            body: body,
            headers: headers,
            signal: signal,
        });
        if(!response.ok){
            throw new Error('Error occurred!');
        }
        const data = await response.json(); 
        return data;
    }catch(err){
        if(err.name === 'AbortError'){
            return { 
                status: false,
                timeout: true
             };
        }
        else if(err.name === 'Error'){
            return { 
                status: false,
                largeContent: true
             };
        }else{
            return { status: false };
        }
        }finally{
            currentController = null;
            clearTimeout(timer);
            clearInterval(timerInterval);
            convertingProcess.setAttribute('hidden', true);
        }    
}   


async function prepareData(file = null, type = null){
    setStatus('');
    let content = null;
    let contentType = null;
    if(file && type){
        content = file;
        contentType = type;
    }
    else{
        let textarea = document.querySelector('textarea#formToSend');
        let dataTypeInput = document.querySelector('#dataTypeInput');
        content = textarea.value;
        contentType = dataTypeInput.value;
    }

    if(contentType == 'file'){
        if(!content){
            return false;
        }else{
            cancelBtnWrapper.removeAttribute('hidden');
            dropFileZone.style.cssText = 'pointer-events: none;';
            fileNameLabel.setAttribute('hidden', true);
            const formData = new FormData();

            formData.append('file', content); 
            formData.append('dataType', contentType);
            formData.append('file_upload', true);

            let formToken = document.querySelector('input[name=_token]').value;
            let body = formData;
            let headers = {
                'X-CSRF-TOKEN': formToken
            };

            let result = await fetchData(body, headers);
            if(result.status){
                cancelBtnWrapper.setAttribute('hidden', true);
                convertingProcess.setAttribute('hidden', true);
                initSvg.setAttribute('hidden', true);
                uploadSvg.removeAttribute('hidden');
                svgText.removeAttribute('hidden');
                svgText.querySelector('[data-init]').setAttribute('hidden', true);
                svgText.querySelector('[data-upload]').removeAttribute('hidden');
                formUploadFileInfo.removeAttribute('hidden');
                formUploadFileInfoText.textContent = fileNameLabel.textContent;
                addConvertButton(result.file, result.fileFormat);
            }else{
                cancelBtnWrapper.setAttribute('hidden', true);
                if(result.timeout){
                    uploadForm.style.cssText = 'background: rgba(225, 77, 77, 0.05);'
                    svgText.querySelector('[data-init]').setAttribute('hidden', true);
                    errorSvg.removeAttribute('hidden');
                    svgText.querySelector('[data-error-timeout]').removeAttribute('hidden');
                    svgText.removeAttribute('hidden');
                }
                else if(result.largeContent){
                    uploadForm.style.cssText = 'background: rgba(225, 77, 77, 0.05);'
                    svgText.querySelector('[data-init]').setAttribute('hidden', true);
                    errorSvg.removeAttribute('hidden');
                    svgText.querySelector('[data-error-large-file]').removeAttribute('hidden');
                    svgText.removeAttribute('hidden');
                }
                else {
                    uploadForm.style.cssText = 'background: rgba(225, 77, 77, 0.05);'
                    svgText.querySelector('[data-init]').setAttribute('hidden', true);
                    errorSvg.removeAttribute('hidden');
                    svgText.querySelector('[data-error]').removeAttribute('hidden');
                    svgText.removeAttribute('hidden');
                };
                setTimeout(() => {
                    initFileForm();
                }, 3000);
            }
        }
    }else if(contentType == 'json' || contentType == 'xml'){
        formSend.setAttribute('readonly', true);
        const formData = new FormData();
        let formToken = document.querySelector('input[name=_token]').value;

        formData.append('content', content); 
        formData.append('dataType', contentType);
        formData.append('string', true);
       
        let body = formData;
    
        let headers = {
            'X-CSRF-TOKEN': formToken
        };
        let result = await fetchData(body, headers);
        if(result.status){
            formSend.removeAttribute('readonly');
            convertError.setAttribute('hidden', true);
            convertProcess.setAttribute('hidden', true);
            formResponse.removeAttribute('hidden');
            convertDataBtn.removeAttribute('hidden');
            formResponseBtns.removeAttribute('hidden');
            formSendBtns.removeAttribute('hidden');
            errorBntWrapper.setAttribute('hidden', true);
            insertResult(result);
        }else{
            errorBntWrapper.setAttribute('hidden', true);
            convertProcess.setAttribute('hidden', true);
            convertError.removeAttribute('hidden');
            formSend.removeAttribute('readonly');
        }
    }
    
}

function initFileForm(){
    setStatus('');
    uploadForm.style.cssText = 'background: rgba(13, 110, 253, 0.0392156863);'
    cancelBtnWrapper.setAttribute('hidden', true);
    dropFileZone.style.cssText = 'pointer-events: auto;';
    labelInputFile.removeAttribute('hidden');
    fileNameLabel.removeAttribute('hidden');
    initSvg.removeAttribute('hidden');
    uploadSvg.setAttribute('hidden', true);
    sucessSvg.setAttribute('hidden', true);
    errorSvg.setAttribute('hidden', true);
    inputFile.value = null;
    fileNameLabel.textContent = lang('Upload file');
    formUploadFileInfo.setAttribute('hidden', true);
    formDownloadOpts.setAttribute('hidden', true);
    svgText.removeAttribute('hidden');
    svgText.querySelector('[data-error]').setAttribute('hidden', true);
    svgText.querySelector('[data-upload]').setAttribute('hidden', true);
    svgText.querySelector('[data-sucess]').setAttribute('hidden', true);
    svgText.querySelector('[data-error-format]').setAttribute('hidden', true);
    svgText.querySelector('[data-error-large-file]').setAttribute('hidden', true);
    svgText.querySelector('[data-error-timeout]').setAttribute('hidden', true);
    svgText.querySelector('[data-init]').removeAttribute('hidden');
    convertingProcess.setAttribute('hidden', true);
    svgText.querySelector('.form-upload__btn').removeAttribute('hidden');
    
    uploadInput.value = null;
}

function clearFileForm(){
    formUploadFileInfo.setAttribute('hidden', true);
    errorSvg.setAttribute('hidden', true);
    sucessSvg.setAttribute('hidden', true);
    initSvg.setAttribute('hidden', true);
    uploadSvg.setAttribute('hidden', true);
    svgText.setAttribute('hidden', true);
    formDownloadOpts.setAttribute('hidden', true);
}

function changeTextArea(){
    let dataTypeInput = document.querySelector('#dataTypeInput');
    let sendForm = document.querySelector('textarea#formToSend');
    let responseForm = document.querySelector('textarea#form-to-response');
    let button1 = document.querySelector('button#control-button-one');
    let button2 = document.querySelector('button#control-button-two');
    
    changeTypeValue(dataTypeInput);
    changeTextareaInfo(sendForm, responseForm, button1, button2);

    sendForm.value = '';
    responseForm.value = '';
    formSend.removeAttribute('readonly');
}
 
function changeTypeValue(dataTypeInput){
    let dataType = dataTypeInput.value;
    if(dataType == 'json')
        dataTypeInput.value = 'xml';
    else
        dataTypeInput.value = 'json';
}

function changeTextareaInfo(sendForm, responseForm, button1, button2){
    if(sendForm.placeholder == lang('Paste your JSON text')){
        sendForm.placeholder = lang('Paste your XML text');
        responseForm.placeholder = lang('Result JSON text');
        button1.textContent = 'XML';
        button2.textContent = 'JSON';
    }else{
        sendForm.placeholder = lang('Paste your JSON text');
        responseForm.placeholder = lang('Result XML text');
        button1.textContent = 'JSON';
        button2.textContent = 'XML';
    }
}

function clearForm(){
    setStatus('');
    formSend.removeAttribute('readonly');
    formResponse.value = '';
    formResponse.removeAttribute('hidden');
    errorBntWrapper.setAttribute('hidden', true);
    convertError.setAttribute('hidden', true);
    convertDataBtn.removeAttribute('hidden');
    formSendBtns.setAttribute('hidden', true);
    formResponseBtns.setAttribute('hidden', true);
}

function copyFromForm(form){
    let text = '';
    if(form == 'copySendForm')
        text = formSend.value;
    else
        text = formResponse.value;

    if(navigator.clipboard){
        navigator.clipboard.writeText(text);
    }else{
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
}

function prepareForDownload(form){
    let text = '';
    let format = '';
    if(form == 'downloadSendForm'){
        text = formSend.value;
        format = document.querySelector('#control-button-one').textContent;
    }else{
        text = formResponse.value;
        format = document.querySelector('#control-button-two').textContent;
    }

    downloadBlob(text, format);
}

function downloadBlob(content, format){
    if(format.toLowerCase() == 'json'){
        const jsonData = JSON.parse(content);
        const jsonBlob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });

        const url = URL.createObjectURL(jsonBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'converted.json';
        a.click();
    }else{
        const xmlDoc = content;
        const xmlBlob = new Blob([xmlDoc], { type: 'application/xml' });

        const url = URL.createObjectURL(xmlBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'converted.xml';
        a.click();
    }
}

function insertResult(data){
    let responseForm = document.querySelector('textarea#form-to-response');
    let text = '';
    if(data.contentType == 'xml'){
        text = new XmlBeautify().beautify(data.content, 
            {
                indent: "  ",  //indent pattern like white spaces
                useSelfClosingElement: true,//true:use self-closing element when empty element.
            });
        text = text.replace('<?xml version="1.0" encoding="sion="1.0"?>', '<?xml version="1.0" encoding="UTF-8"?>');
    }
    else if(data.contentType == 'json'){
        text = data.content;
    }
    responseForm.value = '';
    responseForm.value = text;
}

async function convertFile(convertFileButton){
    setStatus('');
    svgText.setAttribute('hidden', true);
    uploadSvg.setAttribute('hidden', true);
    formUploadFileInfo.setAttribute('hidden', true);

    convertingProcess.removeAttribute('hidden');
    cancelBtnWrapper.removeAttribute('hidden');
    
    let contentType = convertFileButton.dataset.fileformat;
    let content = convertFileButton.dataset.file;

    const formData = new FormData();
    formData.append('file', content); 
    formData.append('fileFormat', contentType);
    formData.append('file_convert', true);
    let formToken = document.querySelector('input[name=_token]').value;
    let body = formData;
    let headers = {
        'X-CSRF-TOKEN': formToken
    };
    let result = await fetchData(body, headers);

    if(result.status){
        convertingProcess.setAttribute('hidden', true);
        cancelBtnWrapper.setAttribute('hidden', true);
        svgText.querySelector('[data-upload]').setAttribute('hidden', true);
        svgText.querySelector('.form-upload__btn').setAttribute('hidden', true);
        sucessSvg.removeAttribute('hidden');
        
        downloadFileButton.textContent = lang('Download file');
        svgText.querySelector('[data-sucess]').removeAttribute('hidden');
        svgText.removeAttribute('hidden');
        formDownloadOpts.removeAttribute('hidden');

        addDownloadButton(result.content, result.format);
    }else{
        clearForm();
        uploadForm.style.cssText = 'background: rgba(225, 77, 77, 0.05);'
        svgText.removeAttribute('hidden');
        svgText.querySelector('[data-upload]').setAttribute('hidden', true);
        svgText.querySelector('[data-error]').removeAttribute('hidden');
        uploadSvg.setAttribute('hidden', true);
        errorSvg.removeAttribute('hidden');
        cancelBtnWrapper.setAttribute('hidden', true);
        setTimeout(() => {
            initFileForm();
        }, 3000);
    }
}

async function downloadFile(downloadButton){
    let file = downloadButton.dataset.file;
    let fileFormat = downloadButton.dataset.fileformat;
    
    let formData = new FormData();
    
    formData.append('file', file); 
    formData.append('fileFormat', fileFormat);
    formData.append('file_download', true);

    let formToken = document.querySelector('input[name=_token]').value;
    let body = formData;
    let headers = {
        'X-CSRF-TOKEN': formToken
    };

    let result = await fetchDownloadFile(body, headers);
}

async function fetchDownloadFile(body, headers) {
    try {
        let response = await fetch(uploadUrl, {
            method: 'POST',
            body: body,
            headers: headers
        });

        if (response.ok) {
            // Создание URL для загрузки файла
            let blob = await response.blob();
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');

            // Извлечение имени файла из заголовка Content-Disposition
            const disposition = response.headers.get('Content-Disposition');
            let filename = 'downloaded_file'; // Имя по умолчанию

            if (disposition && disposition.includes('filename=')) {
                const matches = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, ''); // Удаление кавычек
                }
            }
            a.href = url;
            a.download = filename; // Использование динамического имени файла
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // Освобождение памяти
        } else {
            console.error('Ошибка при загрузке файла:', response.statusText);
        }
    } catch (error) {
        console.error('Ошибка:', error);
    }
}

function addConvertButton(file, fileFormat){
    let button = convertFileButton;
    button.removeAttribute("hidden");
    button.dataset.fileformat = fileFormat;
    button.dataset.file = file;
}

function addDownloadButton(file, fileFormat){
    let button = downloadFileButton;
    button.dataset.fileformat = fileFormat;
    button.dataset.file = file;
}
